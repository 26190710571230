@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.hero {
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #ffffff 100%),
    url(./white-bg.jpg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
  @include breakpoint-up(md) {
    height: calc(var(--vh, 1vh) * 100);
  }
  .text {
    width: 100%;
    text-align: center;
    white-space: pre-line;
    @include breakpoint-up(md) {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
    h1 {
      font-size: 2rem;
      @include breakpoint-up(md) {
        font-size: 3rem;
      }
      @include breakpoint-up(lg) {
        font-size: 5rem;
      }
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    margin-bottom: 10%;
  }
  .btnWrapper {
    text-align: center;
  }
}

.section {
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  .content {
    padding: $navbar-height 20px $footer-mobile-height + 10px 20px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @include breakpoint-up(md) {
      height: 80%;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      max-width: 700px;
    }
  }
}

.info {
  display: block;
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.step {
  color: #c5c5c5;
  font-size: 1.2rem;
  height: 5vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 -20px 20px;
  @include breakpoint-up(md) {
    height: 10vh;
  }
}

.btnWrapper {
  margin-top: 20px;
}
