$primary-color: #ffb601;

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$navbar-height: 70px;
$navbar-mobile-height: 60px;
$footer-height: 50px;
$footer-mobile-height: 90px;
$z-index-loading-overlay: 10;
$z-index-navbar: 10;
$z-index-footer: 5;
$z-index-modal: 30;
$z-index-sidebar-overlay: 7;
$z-index-sidebar: 8;

$font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Montserrat', sans-serif;

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
}
