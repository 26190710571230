@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.fieldWrapper {
  color: black;
  display: flex;
  margin: 15px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  label {
    flex: 2;
    margin-bottom: 5px;
    text-align: left;
    pointer-events: none;
  }
  @include breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    label {
      margin-right: 20px;
      margin-bottom: 0;
      text-align: right;
    }
  }
  input {
    background-color: white;
    border: 1px solid #979797;
    width: 100%;
    flex: 3;
    border-radius: 4px;
    padding: 10px 60px 10px 15px;
  }
}
