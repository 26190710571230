@import './font-face.scss';
@import './variables.scss';
@import './breakpoints.scss';

body {
  font-family: $font-family;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-weight: 700;
}
* {
  box-sizing: border-box;
}

input {
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  background: transparent;
  font-family: $font-family;
  border-radius: 0;
  box-shadow: none;
  font-weight: 500;
  color: #333;
}

.content-wrapper {
  width: 100%;
  margin: $navbar-mobile-height auto;
  padding-left: 15px;
  padding-right: 15px;
  font-family: $font-family-heading;
  word-break: break-word;
  overflow-y: auto;
  @include breakpoint-up(md) {
    margin: $navbar-height auto;
  }
  p,
  li {
    line-height: 25px;
  }
  @media (min-width: 768px) {
    width: 710px;
  }
  @media (min-width: 992px) {
    width: 920px;
  }
  @media (min-width: 1200px) {
    width: 1120px;
  }
  @media (min-width: 1400px) {
    width: 1220px;
  }
}

.fullscreen {
  height: calc(var(--vh, 1vh) * 100);
  @include breakpoint-up(md) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.card {
  border-radius: 4px;
  padding: 1.3rem;
  background-color: white;
  @include breakpoint-up(md) {
    padding: 3rem;
  }
}
