@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400;1,500;1,700&display=swap);
.LanguageSelect_menu__3kepV{background-color:white;list-style-type:none;box-shadow:6px 6px 48px rgba(0,0,0,0.08);outline:none;position:relative;text-align:center;min-width:80px;padding:5px 0}.LanguageSelect_menu__3kepV .LanguageSelect_menuItem__utGbn{cursor:pointer}.LanguageSelect_menu__3kepV .LanguageSelect_menuItem__utGbn::after{content:''}.LanguageSelect_triggeredContent__jOMEj{cursor:pointer}

.Footer_footer__1mb_M{position:fixed;bottom:0;left:0;width:100%;color:white;font-size:14px;height:90px;transition-duration:0.3s;z-index:5}@media (min-width: 768px){.Footer_footer__1mb_M{height:50px;font-size:16px}}.Footer_footer__1mb_M ul{width:100%;list-style-type:none;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:flex-end;justify-content:flex-end;-webkit-flex-direction:column-reverse;flex-direction:column-reverse;padding-left:0}@media (min-width: 768px){.Footer_footer__1mb_M ul{-webkit-flex-direction:row;flex-direction:row}}.Footer_footer__1mb_M ul li{padding:0 20px}.Footer_footer__1mb_M ul li:not(:first-child){margin-bottom:10px}@media (min-width: 768px){.Footer_footer__1mb_M ul li:not(:first-child){margin-bottom:0}}.Footer_footer__1mb_M ul li a{text-decoration:none}.Footer_hideFooter__3HNIy{-webkit-transform:translateY(90px);transform:translateY(90px)}@media (min-width: 768px){.Footer_hideFooter__3HNIy{-webkit-transform:translateY(50px);transform:translateY(50px)}}.Footer_primaryFooter__30gXo{background-color:#ffb601;color:white}.Footer_primaryFooter__30gXo a{color:white}.Footer_whiteFooter__VLQKa{background-color:white;box-shadow:4px 6px 24px rgba(0,0,0,0.05);color:#828282}.Footer_whiteFooter__VLQKa a{color:#828282}.Footer_transparentFooter__4fFCt{background-color:transparent;color:#828282;background:linear-gradient(0deg, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%)}.Footer_transparentFooter__4fFCt a{color:#828282}



.Navbar_navbar__38HqQ{position:fixed;top:0;left:0;width:100%;height:60px;padding:16px 20px;background:linear-gradient(180deg, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%);z-index:10;text-align:center;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}.Navbar_navbar__38HqQ .Navbar_logo__t3h5w{max-width:30px}@media (min-width: 768px){.Navbar_navbar__38HqQ{height:70px;text-align:left}.Navbar_navbar__38HqQ .Navbar_logo__t3h5w{max-width:40px}}

.NumberInput_fieldWrapper__15imm{display:-webkit-flex;display:flex;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:flex-end;justify-content:flex-end;margin:25px 0;position:relative;-webkit-flex-direction:column;flex-direction:column}.NumberInput_fieldWrapper__15imm label{margin-bottom:5px;text-align:left;pointer-events:none;-webkit-flex:2 1;flex:2 1}.NumberInput_fieldWrapper__15imm label:empty{-webkit-flex:0 1;flex:0 1}@media (min-width: 768px){.NumberInput_fieldWrapper__15imm label{text-align:right;margin-bottom:0;margin-right:20px}}@media (min-width: 768px){.NumberInput_fieldWrapper__15imm{margin:15px 0;-webkit-align-items:center;align-items:center;-webkit-flex-direction:row;flex-direction:row}}.NumberInput_fieldWrapper__15imm .NumberInput_numberInput__x_PQJ{width:100%;border:1px solid #979797;border-radius:4px;padding:10px 15px;-webkit-flex:3 1;flex:3 1}@media (min-width: 768px){.NumberInput_fieldWrapper__15imm .NumberInput_numberInput__x_PQJ{width:auto;width:initial}}.NumberInput_fieldWrapper__15imm .NumberInput_endText__13vcm{position:absolute;font-size:12px;right:5px;bottom:13px;pointer-events:none}

.RangeInput_fieldWrapper__2xzw3{text-align:center;position:relative}.RangeInput_fieldWrapper__2xzw3 .RangeInput_pencilIcon__3zz1U{position:absolute;right:0;bottom:33%;cursor:pointer}.RangeInput_fieldWrapper__2xzw3 .RangeInput_hideIcon__2ea30{visibility:hidden}.RangeInput_fieldWrapper__2xzw3 .RangeInput_numberInput__3hZi4{border:none;text-align:center;font-size:3rem;width:100%}@media (min-width: 768px){.RangeInput_fieldWrapper__2xzw3 .RangeInput_numberInput__3hZi4{font-size:4rem}}.RangeInput_fieldWrapper__2xzw3 .RangeInput_slider__1-eDz{margin:2rem 0;-webkit-appearance:none;width:100%;height:8px;padding:0;background-color:#ffb601;outline:none;transition:opacity 0.2s;position:relative;border-radius:6px;padding:0}.RangeInput_fieldWrapper__2xzw3 .RangeInput_slider__1-eDz::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;width:28px;height:28px;background-color:white;border-radius:50%;border:3px solid #ffb601;cursor:pointer;box-shadow:2px 2px 4px rgba(0,0,0,0.1)}.RangeInput_fieldWrapper__2xzw3 .RangeInput_sliderLabel__3vACq{text-transform:uppercase;color:black;font-weight:bold;font-size:1.5rem;text-align:center}@media (min-width: 768px){.RangeInput_fieldWrapper__2xzw3 .RangeInput_sliderLabel__3vACq{font-size:2.5rem}}

.Home_hero__StJPd{overflow:hidden;padding-left:20px;padding-right:20px;background:linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #fff 100%),url(/static/media/white-bg.948ddfc5.jpg);display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;height:calc(1vh * 100);height:calc(var(--vh, 1vh) * 100)}@media (min-width: 768px){.Home_hero__StJPd{height:calc(1vh * 100);height:calc(var(--vh, 1vh) * 100)}}.Home_hero__StJPd .Home_text__39p5C{width:100%;text-align:center;white-space:pre-line;margin-bottom:10%}@media (min-width: 768px){.Home_hero__StJPd .Home_text__39p5C{margin-left:auto;margin-right:auto;width:80%}}.Home_hero__StJPd .Home_text__39p5C h1{font-size:2rem;margin-bottom:20px;text-transform:uppercase}@media (min-width: 768px){.Home_hero__StJPd .Home_text__39p5C h1{font-size:3rem}}@media (min-width: 992px){.Home_hero__StJPd .Home_text__39p5C h1{font-size:5rem}}.Home_hero__StJPd .Home_btnWrapper__3lGp4{text-align:center}.Home_section__2RHn8{overflow:hidden;padding-left:20px;padding-right:20px}.Home_section__2RHn8 .Home_content__OGPl9{padding:70px 20px 100px 20px;height:100%;width:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;-webkit-flex-direction:column;flex-direction:column}@media (min-width: 768px){.Home_section__2RHn8 .Home_content__OGPl9{height:80%;margin-left:auto;margin-right:auto;width:80%;max-width:700px}}.Home_info__f4C-4{display:block;text-align:center;margin-top:2rem;font-size:1.2rem}.Home_step__2PncG{color:#c5c5c5;font-size:1.2rem;height:5vh;width:100%;display:-webkit-flex;display:flex;-webkit-align-items:flex-end;align-items:flex-end;-webkit-justify-content:center;justify-content:center;margin:0 -20px 20px}@media (min-width: 768px){.Home_step__2PncG{height:10vh}}.Home_btnWrapper__3lGp4{margin-top:20px}

.Button_btn__3u62g{border-width:medium;border-width:initial;border-style:none;border-image:none;border-image:initial;outline:0px;background-color:#ffb601;cursor:pointer;padding:1rem 3rem;border-radius:5px;color:white;transition:all 0.3 linear;font-family:"Montserrat",sans-serif;text-transform:uppercase;font-weight:bold;position:relative}.Button_btn__3u62g:hover{transition:all 0.5s linear;background-color:#f6af00}.Button_outline__2fD_Y{background-color:white;color:#ffb601;border:1px solid #ffb601;padding:1.2rem 3.5rem}.Button_outline__2fD_Y:hover{transition:all 0.5s linear;background-color:white}.Button_btnLoading__2MGPU{position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}

:export{primaryColor:#ffb601}

.LoadingOverlay_fullscreen__2CpPV{position:absolute;left:0;top:6;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;background-color:rgba(255,255,255,0.8);z-index:10;width:100%;height:100%}

.Results_resultsContainer__11XUg{background:url(/static/media/accent-bg.c7c75b14.jpg);display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;color:white;text-align:center;padding:50px 15px}.Results_resultsContainer__11XUg .Results_headerText__1F_G4 i,.Results_resultsContainer__11XUg .Results_headerText__1F_G4 b{display:block}.Results_resultsContainer__11XUg .Results_headerText__1F_G4 b{text-transform:uppercase}.Results_resultsContainer__11XUg .Results_independenceDayText__3YtX8{margin:20% 0 10%}@media (min-width: 768px){.Results_resultsContainer__11XUg .Results_independenceDayText__3YtX8{margin:8% 0 4%}}.Results_resultsContainer__11XUg .Results_independenceDayText__3YtX8 i,.Results_resultsContainer__11XUg .Results_independenceDayText__3YtX8 b{display:block;margin:16px 0}.Results_cardsWrapper__3LLpX{width:100%;margin-bottom:5%}@media (min-width: 768px){.Results_cardsWrapper__3LLpX{width:50%;min-width:520px;max-width:630px}}.Results_formWrapper__2zo9Y{margin-top:50px;background-color:white;color:black;width:100%}@media (min-width: 768px){.Results_formWrapper__2zo9Y{margin-top:100px;width:auto;width:initial;padding:50px}}.Results_formWrapper__2zo9Y h2{margin-top:0}.Results_formWrapper__2zo9Y .Results_resultText__3v7RV{margin:20px 0}.Results_formWrapper__2zo9Y .Results_resultText__3v7RV>*{margin:12px 0;display:block;margin:16px 0}.Results_subscribeSection__39OPa{padding:1.3rem;width:100%;margin:30px 0}.Results_subscribeSection__39OPa h3{text-align:left;color:black}@media (min-width: 768px){.Results_subscribeSection__39OPa h3{text-align:center}}@media (min-width: 768px){.Results_subscribeSection__39OPa{padding:2rem !important}}.Results_btnWrapper__aRYFW{margin-top:2rem}.Results_btnWrapper__aRYFW button{width:100%}@media (min-width: 768px){.Results_btnWrapper__aRYFW button{width:auto;width:initial}}

.TextInput_fieldWrapper__3DORU{color:black;display:-webkit-flex;display:flex;margin:15px 0;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:flex-end;justify-content:flex-end}.TextInput_fieldWrapper__3DORU label{-webkit-flex:2 1;flex:2 1;margin-bottom:5px;text-align:left;pointer-events:none}@media (min-width: 768px){.TextInput_fieldWrapper__3DORU{-webkit-flex-direction:row;flex-direction:row;-webkit-align-items:center;align-items:center}.TextInput_fieldWrapper__3DORU label{margin-right:20px;margin-bottom:0;text-align:right}}.TextInput_fieldWrapper__3DORU input{background-color:white;border:1px solid #979797;width:100%;-webkit-flex:3 1;flex:3 1;border-radius:4px;padding:10px 60px 10px 15px}

:export{primaryColor:#ffb601}body{font-family:"Montserrat",sans-serif}h1,h2,h3,h4,h5,h6{font-family:"Montserrat",sans-serif;font-weight:700}*{box-sizing:border-box}input{border:none;width:100%;outline:none;font-size:16px;background:transparent;font-family:"Montserrat",sans-serif;border-radius:0;box-shadow:none;font-weight:500;color:#333}.content-wrapper{width:100%;margin:60px auto;padding-left:15px;padding-right:15px;font-family:"Montserrat",sans-serif;word-break:break-word;overflow-y:auto}@media (min-width: 768px){.content-wrapper{margin:70px auto}}.content-wrapper p,.content-wrapper li{line-height:25px}@media (min-width: 768px){.content-wrapper{width:710px}}@media (min-width: 992px){.content-wrapper{width:920px}}@media (min-width: 1200px){.content-wrapper{width:1120px}}@media (min-width: 1400px){.content-wrapper{width:1220px}}.fullscreen{height:calc(1vh * 100);height:calc(var(--vh, 1vh) * 100)}@media (min-width: 768px){.fullscreen{height:calc(1vh * 100);height:calc(var(--vh, 1vh) * 100)}}.card{border-radius:4px;padding:1.3rem;background-color:white}@media (min-width: 768px){.card{padding:3rem}}

:export{primaryColor:#ffb601}.text-sm{font-size:1rem}@media (min-width: 768px){.text-sm{font-size:1.5rem}}.text-md{font-size:1.5rem}@media (min-width: 768px){.text-md{font-size:2rem}}.text-lg{font-size:3rem;margin:1rem 0}@media (min-width: 768px){.text-lg{font-size:4rem}}.text-bold{font-weight:bold}.text-center{text-align:center}

