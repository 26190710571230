@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $navbar-mobile-height;
  padding: 16px 20px;
  // background-color: white;
  background: linear-gradient(180deg, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%);
  // background-color: transparent;
  z-index: $z-index-navbar;
  text-align: center;
  // box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    max-width: 30px;
  }
  @include breakpoint-up(md) {
    height: $navbar-height;
    text-align: left;
    .logo {
      max-width: 40px;
    }
  }
}
