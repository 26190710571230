.menu {
  background-color: white;
  list-style-type: none;
  box-shadow: 6px 6px 48px rgba(0, 0, 0, 0.08);
  outline: none;
  position: relative;
  text-align: center;
  min-width: 80px;
  padding: 5px 0;
  .menuItem {
    cursor: pointer;
    &::after {
      content: '';
    }
  }
}

.triggeredContent {
  cursor: pointer;
}
