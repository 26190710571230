@import './variables.scss';
@import './breakpoints.scss';

.text-sm {
  font-size: 1rem;
  @include breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

.text-md {
  font-size: 1.5rem;
  @include breakpoint-up(md) {
    font-size: 2rem;
  }
}

.text-lg {
  font-size: 3rem;
  margin: 1rem 0;
  @include breakpoint-up(md) {
    font-size: 4rem;
  }
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}
