@import '../../styles/variables';
@import '../../styles/breakpoints.scss';

.fieldWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 25px 0;
  position: relative;
  flex-direction: column;
  label {
    margin-bottom: 5px;
    text-align: left;
    pointer-events: none;
    flex: 2;
    &:empty {
      flex: 0;
    }
    @include breakpoint-up(md) {
      text-align: right;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
  @include breakpoint-up(md) {
    margin: 15px 0;
    align-items: center;
    flex-direction: row;
  }
  .numberInput {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 4px;
    padding: 10px 15px;
    flex: 3;
    @include breakpoint-up(md) {
      width: initial;
    }
  }
  .endText {
    position: absolute;
    font-size: 12px;
    right: 5px;
    bottom: 13px;
    pointer-events: none;
  }
}
