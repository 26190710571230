@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  font-size: 14px;
  height: $footer-mobile-height;
  transition-duration: 0.3s;
  z-index: $z-index-footer;
  @include breakpoint-up(md) {
    height: $footer-height;
    font-size: 16px;
  }
  ul {
    width: 100%;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column-reverse;
    padding-left: 0;
    @include breakpoint-up(md) {
      flex-direction: row;
    }
    li {
      padding: 0 20px;
      &:not(:first-child) {
        margin-bottom: 10px;
        @include breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}

.hideFooter {
  transform: translateY($footer-mobile-height);
  @include breakpoint-up(md) {
    transform: translateY($footer-height);
  }
}

.primaryFooter {
  background-color: $primary-color;
  color: white;
  a {
    color: white;
  }
}

.whiteFooter {
  background-color: white;
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05);
  color: #828282;
  a {
    color: #828282;
  }
}

.transparentFooter {
  background-color: transparent;
  color: #828282;
  background: linear-gradient(0deg, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0) 100%);
  a {
    color: #828282;
  }
}