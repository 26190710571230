@import '../../styles/variables';
@import '../../styles/breakpoints.scss';

.fieldWrapper {
  text-align: center;
  position: relative;
  // .value {
  //   text-align: center;
  //   font-size: 3rem;
  //   display: block;
  //   @include breakpoint-up(md) {
  //     font-size: 5rem;
  //   }
  // }

  .pencilIcon {
    position: absolute;
    right: 0;
    bottom: 33%;
    cursor: pointer;
  }

  .hideIcon {
    visibility: hidden;
  }

  .numberInput {
    border: none;
    text-align: center;
    font-size: 3rem;
    width: 100%;
    @include breakpoint-up(md) {
      font-size: 4rem;
    }
  }

  .slider {
    margin: 2rem 0;
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    padding: 0;
    background-color: $primary-color;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    position: relative;
    border-radius: 6px;
    padding: 0;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 28px;
      height: 28px;
      background-color: white;
      border-radius: 50%;
      border: 3px solid $primary-color;
      cursor: pointer;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .sliderLabel {
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    @include breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
}
