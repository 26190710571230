@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.resultsContainer {
  background: url(./accent-bg.jpg);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  padding: 50px 15px;

  .headerText {
    i,
    b {
      display: block;
    }
    b {
      text-transform: uppercase;
    }
  }
  .independenceDayText {
    margin: 20% 0 10%;
    @include breakpoint-up(md) {
      margin: 8% 0 4%;
    }
    i,
    b {
      display: block;
      margin: 16px 0;
    }
  }
}

.cardsWrapper {
  width: 100%;
  margin-bottom: 5%;
  @include breakpoint-up(md) {
    width: 50%;
    min-width: 520px;
    max-width: 630px;
  }
}

.formWrapper {
  margin-top: 50px;
  background-color: white;
  color: black;
  width: 100%;
  @include breakpoint-up(md) {
    margin-top: 100px;
    width: initial;
    padding: 50px;
  }
  h2 {
    margin-top: 0;
  }
  .resultText {
    margin: 20px 0;
    & > * {
      margin: 12px 0;
      display: block;
      margin: 16px 0;
    }
  }
}

.subscribeSection {
  padding: 1.3rem;
  width: 100%;
  margin: 30px 0;
  h3 {
    text-align: left;
    color: black;
    @include breakpoint-up(md) {
      text-align: center;
    }
  }
  @include breakpoint-up(md) {
    padding: 2rem !important;
  }
}

.btnWrapper {
  margin-top: 2rem;
  button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    button {
      width: initial;
    }
  }
}
