@import '../styles/variables';
.btn {
  border-width: initial;
  border-style: none;
  border-image: initial;
  outline: 0px;
  background-color: $primary-color;
  cursor: pointer;
  padding: 1rem 3rem;
  border-radius: 5px;
  color: white;
  transition: all 0.3 linear;
  font-family: $font-family-heading;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  &:hover {
    transition: all 0.5s linear;
    background-color: darken($primary-color, 2%);
  }
}

.outline {
  background-color: white;
  color: $primary-color;
  border: 1px solid $primary-color;
  padding: 1.2rem 3.5rem;
  &:hover {
    transition: all 0.5s linear;
    background-color: white;
  }
}

.btnLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
